// const CDN = "https://d2uhurgzmxzel3.cloudfront.net";
const CDNV = "https://d2uhurgzmxzel3.cloudfront.net";
const CDN = "./../assets";

export const RiceFields = `${CDNV}` + `/RiceFields.mp4`;
export const Heaven = `${CDN}` + "/images/onironinheavenlayer.png";
export const Battleground = `${CDN}` + `/images/battleground.png`;

export const FogTop = `${CDN}` + `/images/Fog-top.png`;
export const FogMid = `${CDN}` + `/images/Fog-mid.png`;
export const FogBottom = `${CDN}` + `/images/Fog-bottom.png`;

export const RoninLarge = `${CDN}` + `/images/Ronin-Solo1.png`;
export const RoninMobile = `${CDN}` + `/images/Ronin-Solo_mobile.png`;

export const FallenRoninLarge = `${CDN}` + "/images/fallen-ronin.png";
export const FallenRoninMobile = `${CDN}` + "/images/fallen-ronin_mobile.png";

export const JiseiLarge = `${CDN}` + "/images/JiseiRonin.png";
export const JiseiMobile = `${CDN}` + "/images/JiseiRonin_mobile.png";

export const KitsuneLarge = `${CDN}` + "/images/Kitsune.png";
export const KitsuneMobile = `${CDN}` + "/images/Kitsune_mobile.png";

export const GoldenRoninLarge = `${CDN}` + "/images/GoldenRonin.png";
export const GoldenRoninMobile = `${CDN}` + "/images/GoldenRonin_mobile.png";

export const TicketBox = `${CDN}` + "/images/ascension/ticketbox.png";
export const AscensionBlockFire = `${CDN}` + "/images/ascension/ascension-block-fire.png";
export const AscensionBlockPlain = `${CDN}` + "/images/ascension/ascension-block-plain.png";
export const AscensionBlockGlow = `${CDN}` + "/images/ascension/ascension-block-glow.png";

export const KitsuneUnclaimed = `${CDN}` + "/images/kitsune-unclaimed.png";
export const KitsuneClaimedBlock = `${CDN}` + "/images/kitsune-claimed-block.png";
export const KitsuneClaimedBackground = `${CDN}` + "/images/kitsune-claimed-background.jpg";

export const Logo = `${CDN}` + "/images/bighead.png";
export const Twitter = `${CDN}` + "/images/Twitter.png";
export const Medium = `${CDN}` + "/images/Medium.png";
export const IG = `${CDN}` + "/images/Instagram.png";
export const FB = `${CDN}` + "/images/Facebook.png";
export const Discord = `${CDN}` + "/images/Discord.png";

export const mint_bg = `${CDNV}` + "/images/MintPage/mint-block.png";
export const mint_ticket = `${CDNV}` + "/images/MintPage/mint-ticketbox.png";

export const HeavenImage = `${CDN}` + "/images/sunrise_battleground.png";
export const Embers = `${CDN}` + "/images/embers.png";

export const Torii = `${CDN}` + "/images/torii_hidef.png";
export const ToriiGates = `${CDN}` + "/images/toriigates.png";
export const success_block = `${CDN}` + "/images/successBlock.png";
export const asc_bg = `${CDNV}` + "/images/AscensionPage/ascension-block.png";

export const BHCMembership = `${CDN}` + "/images/bhc_membership.png";
export const CommercialRights = `${CDN}` + "/images/comm_rights.png";
export const SamuraiLessons = `${CDN}` + "/images/samurai_history.png";
export const HaikuWorkshop = `${CDN}` + "/images/haiku_workshop.png";
export const ZenMeditation = `${CDN}` + "/images/zen_meditation.png";
export const JapaneseTeaCeremony = `${CDN}` + "/images/tea_ceremony.png";
export const FlowerArrangements = `${CDN}` + "/images/flowers.png";
export const LanguageLessons = `${CDN}` + "/images/nihongolessons.png";
export const KitsuneCompanion = `${CDN}` + "/images/kitsune_companion.png";
export const CustomOniRonin = `${CDN}` + "/images/custom_oni.png";
export const OniRoninComics = `${CDN}` + "/images/onironin_comics.png";
export const AAPI = `${CDN}` + "/images/stop_aapi_hate.png";
export const WhatsToCome = `${CDN}` + "/images/whats2come.png";
export const CustomMerch = `${CDN}` + "/images/custom_merch_2.png";

export const RiceFieldsStill = `${CDNV}` + "/RiceFieldsStill.jpg";
// export const something = `${CDN}` + ''

export const BackgroundCement = `${CDN}` + "/images/background-cement.jpg";

export const comicMockup = `${CDN}` + "/images/comicMockup.png";